import { render, staticRenderFns } from "./identify.vue?vue&type=template&id=2e9e2378&scoped=true&"
import script from "./identify.vue?vue&type=script&lang=js&"
export * from "./identify.vue?vue&type=script&lang=js&"
import style0 from "./identify.vue?vue&type=style&index=0&id=2e9e2378&prod&lang=less&scoped=scoped&"
import style1 from "./identify.vue?vue&type=style&index=1&id=2e9e2378&prod&scoped=scoped&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9e2378",
  null
  
)

export default component.exports